import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { margin, styled } from "@mui/system";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import { ArrowDropDownCircle } from "@mui/icons-material";
import ailoader from "../../assets/ailoader.gif";

const Container = styled("div")({
  backgroundColor: "#FFFFFF08",
  border: "1px solid #FFFFFF0F",
  borderRadius: "10px",
  padding: "20px",
  color: "#fff",
  fontStyle: "Roboto",
});

const LegendWrapper = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(3, auto)",
  gap: "16px",
  justifyContent: "center",
  marginTop: "16px",
  background: "#FFFFFF0F",
  borderRadius: "4px",
  padding: "8px",
  color: "#fff",
  fontSize: "12px",
  fontStyle: "Roboto",
});

export const CommodityPriceChart = ({ filteredResults }) => {
  const [selectedCategory, setSelectedCategory] = useState(() => {
    return filteredResults && filteredResults.length > 0
      ? filteredResults[0].category
      : "";
  });
  const LoadingGif = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50px",
        width: "auto",
      }}
    >
      <img
        src={ailoader}
        alt="Loading..."
        style={{ width: "30px", height: "30px" }}
      />
    </Box>
  );
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const formatDate = (dateStr) => {
    try {
      const date = dateStr.includes("/")
        ? new Date(dateStr.split("/").reverse().join("-"))
        : new Date(dateStr);

      if (isNaN(date.getTime())) {
        console.warn("Invalid date:", dateStr);
        return dateStr;
      }

      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear().toString().slice(-2);
      return `${month}-${year}`;
    } catch (error) {
      console.warn("Error formatting date:", dateStr, error);
      return dateStr;
    }
  };

  const renderChart = () => {
    const selectedCategoryData = filteredResults.find(
      (category) => category.category === selectedCategory
    );

    if (!selectedCategoryData) return null;

    const allSeries = selectedCategoryData.series;
    const mainCategoryData = allSeries.find(
      (series) => series.type === "main_category"
    );
    const costMaterialData = allSeries.filter(
      (series) => series.type === "cost_structure"
    );

    const dateMap = new Map();

    if (mainCategoryData && mainCategoryData.series_data?.content?.points) {
      mainCategoryData.series_data.content.points.forEach((point) => {
        dateMap.set(point.date, {
          date: formatDate(point.date),
          [mainCategoryData["Series Name"]]: point.value,
        });
      });
    }

    costMaterialData.forEach((costMaterial) => {
      if (costMaterial.series_data?.content?.points) {
        costMaterial.series_data.content.points.forEach((point) => {
          const existingPoint = dateMap.get(point.date) || {
            date: formatDate(point.date),
          };
          existingPoint[costMaterial["Series Name"]] = point.value;
          dateMap.set(point.date, existingPoint);
        });
      }
    });

    let chartData = Array.from(dateMap.values())
      .map((item) => {
        const [month, year] = item.date.split("-");
        const monthIndex = new Date(`${month} 1`).getMonth();
        const fullDate = new Date(parseInt(`20${year}`), monthIndex, 1);
        return { ...item, fullDate };
      })
      .sort((a, b) => a.fullDate - b.fullDate)
      .map(({ fullDate, ...rest }) => rest);

    // Smoothing function
    const smoothData = (data, seriesName, smoothingFactor = 0.3) => {
      return data.map((point, index) => {
        if (index === 0) return point;
        return {
          ...point,
          [seriesName]:
            point[seriesName] * smoothingFactor +
            data[index - 1][seriesName] * (1 - smoothingFactor),
        };
      });
    };

    // Apply smoothing to each series
    chartData = chartData.map((dataPoint) => {
      const smoothedPoint = { ...dataPoint };

      // Smooth main category if it exists
      if (mainCategoryData && mainCategoryData["Series Name"]) {
        const seriesName = mainCategoryData["Series Name"];
        if (smoothedPoint[seriesName]) {
          smoothedPoint[seriesName] = smoothData(chartData, seriesName).find(
            (p) => p.date === dataPoint.date
          )[seriesName];
        }
      }

      // Smooth cost material series
      costMaterialData.forEach((costMaterial) => {
        const seriesName = costMaterial["Series Name"];
        if (smoothedPoint[seriesName]) {
          smoothedPoint[seriesName] = smoothData(chartData, seriesName).find(
            (p) => p.date === dataPoint.date
          )[seriesName];
        }
      });

      return smoothedPoint;
    });

    const colors = [
      "#FFC107",
      "#FF5722",
      "#4CAF50",
      "#03A9F4",
      "#E91E63",
      "#9C27B0",
      "#673AB7",
      "#00BCD4",
      "#8BC34A",
      "#FF9800",
    ];

    return (
      <ResponsiveContainer width="100%" height={421}>
        <LineChart data={chartData}>
          <XAxis
            dataKey="date"
            axisLine={{ stroke: "#FFFFFF", strokeWidth: 1 }} // Add this line
            // tickLine={false}
            tick={{ fill: "#FFFFFF" }}
            style={{ fontSize: "12px", fontStyle: "Roboto" }}
            padding={{ left: 30 }}
            interval="preserveStartEnd"
            allowDuplicatedCategory={false}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#FFFFFF" }}
            style={{ fontSize: "12px", fontStyle: "Roboto" }}
            label={{
              value: "Price Trend",
              angle: -90,
              position: "insideLeft",
              style: {
                fill: "#FFFFFF",
                fontSize: "12px",
                fontStyle: "Roboto",
                textAnchor: "middle",
              },
            }}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: "#FFFFFF08",
              color: "#fff",
              fontSize: "12px",
              fontStyle: "Roboto",
              border: "1px solid #FFFFFF0F",
            }}
            cursor={{ stroke: "#555", strokeWidth: 1 }}
            formatter={(value, name) => [Number(value.toFixed(2)), name]}
          />
          <Legend
            content={({ payload }) => (
              <LegendWrapper>
                {payload.map((entry, index) => (
                  <div
                    key={`item-${index}`}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: entry.color,
                        marginRight: "8px",
                        borderRadius: "50%",
                      }}
                    />
                    <span>{entry.value}</span>
                  </div>
                ))}
              </LegendWrapper>
            )}
          />

          {mainCategoryData &&
            mainCategoryData.series_data?.content?.points && (
              <Line
                type="basis" // Changed to "basis" for smoother lines
                dataKey={mainCategoryData["Series Name"]}
                stroke={colors[0]}
                name={`${mainCategoryData["Series Name"]} (${
                  mainCategoryData.series_data?.Unit || ""
                })`}
                dot={false}
                strokeWidth={2}
              />
            )}

          {costMaterialData.map((costMaterial, index) =>
            costMaterial.series_data?.content?.points ? (
              <Line
                key={costMaterial["Series Code"]}
                type="basis" // Changed to "basis" for smoother lines
                dataKey={costMaterial["Series Name"]}
                stroke={colors[(index + 1) % colors.length]}
                name={`${costMaterial["Series Name"]} (${
                  costMaterial.series_data?.Unit || ""
                })`}
                dot={false}
                strokeWidth={2}
              />
            ) : null
          )}
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Typography
          variant="h6"
          color="white"
          style={{
            fontStyle: "Roboto",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "18px",
            color: "white",
            marginBottom: "1.5rem",
          }}
        >
          <b>Commodity prices for top categories</b>
        </Typography>
        {filteredResults && filteredResults.length > 0 ? (
          <Select
            sx={{
              maxWidth: "120px",
              width: "auto",
              height: "24px",
              padding: "4px 8px",
              fontStyle: "Roboto",
              fontSize: "12px",
              fontWeight: 500,
              gap: "4px",
              borderRadius: "4px",
              opacity: 1,
              background: "#FFFFFF0F",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #E2E8F00F",
              marginBottom: "1.5rem",
              "& .MuiSelect-select": {
                padding: "0",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
            IconComponent={(props) => (
              <ArrowDropDownCircle
                {...props}
                style={{
                  color: "white",
                  fontSize: "12px",
                }}
              />
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  background: "white",
                },
              },
              MenuListProps: {
                sx: {
                  fontStyle: "Roboto",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "15px",
                },
              },
            }}
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            {filteredResults.map((category) => (
              <MenuItem
                key={category.category}
                value={category.category}
                sx={{
                  fontSize: "12px",
                  fontStyle: "Roboto",
                  fontWeight: 500,
                  lineHeight: "15px",
                }}
              >
                {category.category}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <LoadingGif />
        )}
      </div>
      {selectedCategory && renderChart()}
    </Container>
  );
};
