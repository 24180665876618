import React from "react";
import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import SpendDashboard from "./components/Rapid_Insights/SpendDashboard";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import ProtectedRoute from "./components/Rapid_Insights/SSO";

const msalConfig = {
  auth: {
    clientId: "f9607ab5-1b08-4282-be7d-e464af21059b",
    authority:
      "https://login.microsoftonline.com/5dc645ed-297f-4dca-b0af-2339c71c5388/",
    redirectUri: window.location.origin,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  console.log("@".repeat(40));
  console.log("Fronend Version 5 : Magic Cube With Custom Domain Reflection");
  console.log("@".repeat(40));

  return (
    <MsalProvider instance={msalInstance}>
      <Routes>
        {/* Other routes */}
        <Route
          exact
          path="/"
          element={
            <ProtectedRoute>
              {" "}
              <SpendDashboard />{" "}
            </ProtectedRoute>

            // <SpendDashboard />
          }
        />
      </Routes>
    </MsalProvider>
  );
}

export default App;
