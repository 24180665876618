/**
 * Environment Variables
 *
 * @typedef {Object} ENV_VARS
 * @property {string} NODE_ENV - The environment in which the application is running. Can be 'development' or 'production'.
 * @property {string} REACT_APP_GOOGLE_TRANSLATE_API_KEY - API key for Google Translate service.
 * @property {string} REACT_APP_BACKEND_URL_DEV - Backend API URL used during development.
 * @property {string} REACT_APP_BACKEND_URL_PROD - Backend API URL used in production.
 */

/**
 * Access environment variables
 *
 * @type {ENV_VARS}
 */
export const ENV_VARS = process.env;

export const BACKEND_BASE_URL = "https://assistas.kearney.com/api";
// export const BACKEND_BASE_URL = "http://localhost:5000/api";

export const ALLOWED_FILE_FORMATS = [".csv"];

export const GUEST_USER_EMAIL = ENV_VARS["REACT_APP_GUEST_USER_EMAIL"];
export const GUEST_USER_PASSWORD = ENV_VARS["REACT_APP_GUEST_USER_PASSWORD"];
