import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import axios from "axios";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Paper,
  Divider,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Button,
  Drawer,
  ListItemIcon,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Fade,
  Stack,
  Select,
  IconButton,
  AppBar,
  Toolbar,
  Slide,
  InputLabel,
  CssBaseline,
  ToggleButtonGroup,
  ToggleButton,
  Link,
  CircularProgress,
  Menu,
  TextField,
  InputAdornment,
  SvgIcon,
  Skeleton,
  Breadcrumbs,
} from "@mui/material";
import {
  AttachMoney,
  Category,
  Business,
  TrendingUp,
  TrendingDown,
  Remove,
  PieChart,
  Close as CloseIcon,
  CloudUpload,
  Dashboard,
  FileDownload,
  CheckCircle,
  RadioButtonUnchecked,
  AccessTime,
  AutoAwesome,
  Campaign,
  Delete,
  ArrowDropDown,
  ArrowDropDownCircle,
  PriceCheck,
  CreditScore,
  CurrencyExchange,
  Info,
  Groups,
  PersonSearch,
  HelpOutline,
  Speed,
  PanToolAlt,
  TrendingFlat,
  PeopleAltOutlined,
  CampaignOutlined,
  FiberManualRecord,
  AccessTimeFilledOutlined,
  AccessTimeFilled,
  Co2,
  EnergySavingsLeaf,
  Insights,
  InsightsOutlined,
  ArrowUpward,
  ArrowDownward,
  HorizontalRule,
  TrendingUpOutlined,
  TrendingDownOutlined,
  TrendingFlatOutlined,
  FilterCenterFocus,
  TipsAndUpdates,
  NavigateNext,
  ReportProblem,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import FolderIcon from "@mui/icons-material/Folder";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Cell,
  Label,
  Text,
  LineChart,
  Legend,
  Line,
  ComposedChart,
  Treemap,
  Rectangle,
  ScatterChart,
  Scatter,
  ZAxis,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Tooltip as MUITooltip } from "@mui/material";
import { styled } from "@mui/system";
// import { TrendingDown, Minus, PieChart, ChevronDown ,X} from 'lucide-react';
import { TypeAnimation } from "react-type-animation";
import bggenai from "../../assets/bggenai.png";
import bgsd from "../../assets/bgsd.png";
import { Search } from "lucide-react";
import ailoader from "../../assets/ailoader.gif";
import bgSS from "../../assets/bgSS.png";
import { IoIosLocate } from "react-icons/io";
import { FaHome, FaUpload, FaChartBar, FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
// import { withTranslation } from "react-google-multi-lang";
// import { translateBulk } from "./translationService";
import { TbTopologyStar3 } from "react-icons/tb";

export const MarketTable = ({ categoryResponse, forecast, spendSummary }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openNewsDialog, setOpenNewsDialog] = useState(false);
  const [openTrendDialog, setOpenTrendDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedForecasts, setSelectedForecasts] = useState([]); // Store forecast data

  // Default to empty objects for each category
  const categories = categoryResponse.filter((category) => category !== null);

  const formatSpend = (amount) => {
    const currencySymbol =
      spendSummary.spend_summary.currency_value === "EUR"
        ? "€"
        : spendSummary.spend_summary.currency_value === "USD"
        ? "$"
        : spendSummary.spend_summary.currency_value === "GBP"
        ? "£"
        : spendSummary.spend_summary.currency_value === "AUD"
        ? "A$"
        : spendSummary.spend_summary.currency_value;

    if (amount >= 1e9) {
      return `${currencySymbol}${Math.round(amount / 1e9)}B`;
    } else if (amount >= 1e6) {
      return `${currencySymbol}${Math.round(amount / 1e6)}M`;
    } else {
      return `${currencySymbol}${Math.round(amount)}`;
    }
  };

  const formatMessage = (message) => {
    if (!message) return null;

    const sections = message
      .split("###")
      .filter((section) => section.trim() !== "");

    return sections.map((section, index) => {
      const colonIndex = section.indexOf(": ");
      const title = section.slice(0, colonIndex + 1);
      const content = section.slice(colonIndex + 1);

      const contentColonIndex = content.indexOf(":");
      const contentBeforeColon = content.slice(0, contentColonIndex + 1);
      const contentAfterColon = content.slice(contentColonIndex + 1);

      return (
        <React.Fragment key={index}>
          <strong style={{ color: "white", fontSize: "12px", fontWeight: 500 }}>
            {title.trim()}
          </strong>
          <span style={{ color: "white", fontSize: "12px", fontWeight: 500 }}>
            <strong
              style={{ color: "white", fontSize: "12px", fontWeight: 700 }}
            >
              {contentBeforeColon}
            </strong>
            {contentAfterColon}
          </span>
          {index < sections.length - 1 && (
            <>
              <br />
              <br />
            </>
          )}
        </React.Fragment>
      );
    });
  };

  const filteredCategories = (spend, searchTerm) =>
    spend && typeof spend === "object"
      ? Object.entries(spend)
          .filter(([category]) =>
            category.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .sort(([, a], [, b]) => b["Order Amount"] - a["Order Amount"])
      : [];

  const handleNewsDialogOpen = () => {
    setOpenNewsDialog(true);
  };

  const handleNewsDialogClose = () => {
    setOpenNewsDialog(false);
  };

  const formatNewsContent = () => {
    return categories
      .filter(
        (categoryResponse) =>
          categoryResponse !== null && categoryResponse !== undefined
      )
      .flatMap((categoryResponse, index) => {
        const { news } = categoryResponse || {};

        if (!news || typeof news !== "object") {
          return []; // Return an empty array if news is null, undefined, or not an object
        }

        return Object.entries(news).map(([category, newsItems], i) => (
          <Grid
            key={`${index}-${i}`}
            item
            xs={12}
            md={5.5}
            sx={{
              backgroundColor: "#FFFFFF0F",
              width: "100%",
              border: "1px solid transparent",
              borderRadius: "10px",
              padding: { xs: "10px", sm: "20px" },
              gap: "12px",
              mb: 4,
            }}
          >
            <Box display="flex" flexDirection="column">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                flexWrap="wrap"
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "Roboto",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "16.94px",
                    textAlign: "left",
                    color: "white",
                    marginBottom: { xs: "10px", sm: 0 },
                  }}
                >
                  {category}
                </Typography>
              </Box>

              {Array.isArray(newsItems) && newsItems.length > 0 ? (
                newsItems.map((item, itemIndex) => (
                  <Card
                    key={itemIndex}
                    sx={{
                      mb: 2,
                      display: "flex",
                      backgroundColor: "rgba(129, 129, 129, 0.25)",
                      boxShadow: "none",
                      border: "1px solid rgba(255, 255, 255, 0.5)",
                      borderRadius: "8px",
                      width: "100%",
                      height: "120px",
                      flexDirection: { xs: "column", sm: "row" },
                      overflow: "hidden",
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        width: { xs: "100%", sm: "100px" },
                        height: { xs: "140px", sm: "140px" },
                        objectFit: "cover",
                      }}
                      image={
                        item.thumbnail && item.thumbnail.trim() !== ""
                          ? item.thumbnail
                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0x2fwUoET9YatuWt5NZjc_2KiDFUCLF4k8w&s"
                      }
                      alt={item.title}
                    />

                    <CardContent
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        color: "white",
                        fontSize: "0.8rem",
                        padding: "8px",
                        "&:last-child": { paddingBottom: "8px" },
                        minHeight: 0,
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontStyle: "Roboto",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "16.94px",
                          textAlign: "left",
                          mb: 0.5,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontStyle: "Roboto",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "16.94px",
                          textAlign: "left",
                          color: "#FFFFFF66",
                          mb: 0.5,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {item.link}
                        </a>
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                          width: "100%",
                          flexWrap: "wrap",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontStyle: "Roboto",
                            fontSize: "12px",
                            fontWeight: 500,
                            lineHeight: "16.94px",
                            color: "#FFFFFF66",
                            marginRight: "10px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: { xs: "100%", sm: "calc(100% - 100px)" },
                          }}
                        >
                          {item.source_name}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            fontStyle: "Roboto",
                            fontSize: "12px",
                            fontWeight: 500,
                            lineHeight: "16.94px",
                            color: "rgba(255, 255, 255, 0.7)",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.date
                            ? (() => {
                                const parts = item.date.split(",");
                                return parts.length > 2
                                  ? parts.slice(0, 2).join(",")
                                  : item.date;
                              })()
                            : "9/23/2024"}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                ))
              ) : (
                <Typography sx={{ color: "white" }}>
                  No news available for this category.
                </Typography>
              )}
            </Box>
          </Grid>
        ));
      });
  };

  const renderTrendIcon = (category) => {
    return (
      <TrendingUpOutlined
        style={{ color: "green", cursor: "pointer" }}
        onClick={() => handleTrendIconClick(category)}
      />
    );
  };

  const handleTrendIconClick = (category) => {
    setSelectedCategory(category);

    // Find all dictionaries that have the selected category
    const relevantDicts = Object.entries(forecast).filter(
      ([key, value]) => value.hasOwnProperty(category) && key !== "columns"
    );

    if (relevantDicts.length > 0) {
      const categoryForecasts = relevantDicts.map(([dictKey, dictValue]) => {
        const graphData = Object.entries(dictValue[category]).map(
          ([date, value]) => ({
            date: date,
            value: parseFloat(value),
          })
        );

        // Sort the data by date
        graphData.sort((a, b) => new Date(a.date) - new Date(b.date));

        return {
          key: dictKey,
          value: graphData,
          columns: dictValue.columns || { key: "Date", value: "Value" }, // Use default if not present
        };
      });

      setSelectedForecasts(categoryForecasts);
      console.log("categoryForecasts", categoryForecasts);
    } else {
      console.log("Category not found in forecast data");
      // Generate random forecast data if category is not found
      setSelectedForecasts([
        {
          key: category,
          value: generateMockTrendData(),
          columns: { key: "Date", value: "Value" },
        },
      ]);
    }

    setOpenTrendDialog(true);
  };

  const handleTrendDialogClose = () => {
    setOpenTrendDialog(false);
    setSelectedForecasts([]);
  };

  // Function to generate random trend data
  const generateMockTrendData = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months.map((month) => ({
      month,
      percentChange: Math.random() * 10 - 5, // Random values between -5 and 5
    }));
  };

  const calculatePriceChange = (data) => {
    if (data.length < 2) return null;

    // Sort data by date
    const sortedData = [...data].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    const lastDate = new Date(sortedData[sortedData.length - 1].date);
    const twelveMonthsAgo = new Date(lastDate);
    twelveMonthsAgo.setFullYear(lastDate.getFullYear() - 1);

    const lastValue = sortedData[sortedData.length - 1].value;
    const startValue = sortedData.find(
      (item) => new Date(item.date) >= twelveMonthsAgo
    )?.value;

    if (!startValue) return null;

    const percentChange = ((lastValue - startValue) / startValue) * 100;
    return percentChange.toFixed(2);
  };

  // const formatPercentage = (value) => {
  //   return (value).toFixed(0);
  // };

  return (
    <>
      <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search categories..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            width: "192px",
            backgroundColor: "#FFFFFF0F",
            border: "1px solid #E2E8F00F",
            boxShadow: "0px 1px 2px 0px #1018280D",
            height: "28px",
            borderRadius: "4px",
            marginLeft: "1px",
            color: "white",
            "& .MuiOutlinedInput-root": {
              width: "192px",
              backgroundColor: "#FFFFFF0F",
              border: "1px solid #E2E8F00F",
              boxShadow: "0px 1px 2px 0px #1018280D",
              height: "28px",
              fontSize: "12px",
              color: "white",
              borderRadius: "4px",
              "& fieldset": { border: "1px solid #E2E8F00F" },
              "&:hover fieldset": { border: "1px solid #E2E8F00F" },
              "&.Mui-focused fieldset": { border: "1px solid #E2E8F00F" },
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search
                  style={{ color: "white", width: "14px", height: "14px" }}
                />
              </InputAdornment>
            ),
          }}
        />

        <Button
          variant="contained"
          onClick={handleNewsDialogOpen}
          sx={{
            width: "98px",
            height: "28px",
            padding: "4px 8px",
            gap: "4px",
            borderRadius: "4px",
            marginLeft: "8px",
            background: "linear-gradient(90deg, #496AB3 2.73%, #A237FE 95.45%)",
            "&:hover": {
              background:
                "linear-gradient(90deg, #3B569E 2.73%, #8A2EE0 95.45%)",
            },
            textTransform: "none",
          }}
        >
          <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
            Read More
          </Typography>
        </Button>
      </Box>

      <Box
        sx={{
          mb: 1,
          maxWidth: "100%",
          overflowX: "auto",
          border: "1px solid #FFFFFF0F",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF0F",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "transparent",
            maxHeight: 440,
            "&::-webkit-scrollbar": {
              width: "2px",
              height: "10px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#2a2b2f",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "4px",
              "&:hover": {
                background: "#555",
              },
            },
          }}
        >
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
            <TableHead
              sx={{
                "& th": {
                  backgroundColor: "#353641", // Solid background color
                  color: "white",
                  fontSize: "12px",
                  fontWeight: 700,
                  padding: "8px",
                  borderBottom: "1px solid #FFFFFF0F",
                  zIndex: 3, // Higher z-index
                },
              }}
            >
              <TableRow>
                {["Category", "Spend", "Market Trend Description"].map(
                  (header, index) => (
                    <TableCell
                      key={index}
                      align={header === "Spend" ? "right" : "left"}
                    >
                      {header}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {categories
                // 1. Flatten all data from categories
                .flatMap((categoryResponse, catIndex) => {
                  if (!categoryResponse) return [];
                  const {
                    spend = {},
                    forecast = {},
                    messages = {},
                  } = categoryResponse || {};

                  // Filter and map to a usable format: [{ category, info, catIndex }]
                  const filteredData = filteredCategories(
                    spend,
                    searchTerm
                  ).map(([category, info]) => ({
                    category,
                    info,
                    catIndex, // Store catIndex to alternate background colors
                    message: messages[category], // Attach the message for this category
                  }));

                  return filteredData;
                })
                // 2. Sort the combined array by Order Amount (descending)
                .sort((a, b) => b.info["Order Amount"] - a.info["Order Amount"])

                // 3. Render Table Rows
                .map(({ category, info, catIndex, message }, index) => (
                  <TableRow
                    key={`${category}-${index}`}
                    sx={{
                      borderBottom: "1px solid #FFFFFF0F",
                      backgroundColor:
                        index % 2 === 0 ? "#FFFFFF05" : "transparent",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: "white", fontSize: "12px", fontWeight: 400 }}
                    >
                      {category}
                    </TableCell>

                    <TableCell
                      align="right"
                      sx={{ color: "white", fontSize: "12px", fontWeight: 500 }}
                    >
                      {formatSpend(info["Order Amount"])}
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{ color: "white", width: "700px" }}
                    >
                      {formatMessage(message)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* <div style={{marginTop:'4rem'}}>
        <MarketChart/>
  </div> */}
      {/* News Dialog */}
      <Dialog
        open={openNewsDialog}
        onClose={handleNewsDialogClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            backgroundImage: `url(${bggenai})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundColor: "rgba(129, 129, 129)",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle sx={{ color: "white", fontSize: "0.9rem" }}>
          Latest Market Insights
          <IconButton
            onClick={handleNewsDialogClose}
            sx={{ position: "absolute", right: 8, top: 8, color: "white" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ borderColor: "rgba(255, 255, 255,0.5)" }}>
          {formatNewsContent()}
        </DialogContent>
        {/* <DialogActions sx={{ backgroundColor: '#21222D' }}>
            <Button onClick={handleNewsDialogClose} color="primary">
              Close
            </Button>
          </DialogActions> */}
      </Dialog>

      {/*} {categories.map((categoryResponse, index) => (
        <div key={index}>
          {Object.keys(categoryResponse || {}).map((category, catIndex) => (
            <div key={catIndex}>
              <span>{category}</span>
      
              <TrendingUpOutlined
                style={{ color: 'green', cursor: 'pointer' }}
                onClick={() => handleTrendIconClick(category)}
              />
            </div>
          ))}
        </div>
      ))}

      {/* Dialog for showing trend charts */}
      <Dialog
        open={openTrendDialog}
        onClose={handleTrendDialogClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: {
            backgroundImage: `url(${bgsd})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#FFFFFF08",
            boxShadow: "none",
            height: "140%",
            // width:'140rem'
          },
        }}
      >
        <DialogTitle
          sx={{ color: "white", fontSize: "18px", fontStyle: "Roboto" }}
        >
          Price Trends for {selectedCategory}
          {/* Calculate and display the date range for the chart */}
          {selectedForecasts.length > 0 && (
            <Typography
              sx={{ color: "#808080", fontSize: "14px", fontStyle: "Roboto" }}
            >
              <i>
                {new Date(
                  Math.min(
                    ...selectedForecasts[0].value.map(
                      (item) => new Date(item.date)
                    )
                  )
                ).toLocaleDateString("en-US", { month: "short" }) +
                  "-" +
                  new Date(
                    Math.min(
                      ...selectedForecasts[0].value.map(
                        (item) => new Date(item.date)
                      )
                    )
                  ).toLocaleDateString("en-US", { year: "2-digit" })}
                {" to "}
                {new Date(
                  Math.max(
                    ...selectedForecasts[0].value.map(
                      (item) => new Date(item.date)
                    )
                  )
                ).toLocaleDateString("en-US", { month: "short" }) +
                  "-" +
                  new Date(
                    Math.max(
                      ...selectedForecasts[0].value.map(
                        (item) => new Date(item.date)
                      )
                    )
                  ).toLocaleDateString("en-US", { year: "2-digit" })}
              </i>
            </Typography>
          )}
          <IconButton
            onClick={handleTrendDialogClose}
            sx={{ position: "absolute", right: 8, top: 8, color: "white" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {/* <Typography variant="h6" sx={{ color: 'white' }}>
    Trend for {selectedCategory}
  </Typography> */}
          {selectedForecasts.map(({ key, value, columns }, index) => (
            <React.Fragment key={index}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <Typography
                  sx={{ color: "white", fontSize: "14px", fontStyle: "Roboto" }}
                >
                  {key}
                </Typography>
                {calculatePriceChange(value) !== null && (
                  <Box
                    sx={{
                      //  position: "relative",
                      //  top: 20,
                      //  left: 700,
                      width: "310px",
                      height: "40px",
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      borderRadius: "8px",
                      padding: "10px",
                      color: "white",
                      fontStyle: "Roboto",
                      fontSize: "12px",
                      //  textAlign: "left",
                      boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.2)",
                      zIndex: 1,
                      display: "flex", // Ensure content is in a horizontal line
                      alignItems: "center", // Align vertically within the box
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "14px",
                        fontStyle: "Roboto",
                      }}
                    >
                      <TipsAndUpdates
                        sx={{
                          mr: 0.5,
                          verticalAlign: "middle",
                          fontSize: "20px",
                        }}
                      />
                      Price change of {Math.round(calculatePriceChange(value))}%
                      in past 12 months
                    </Typography>
                  </Box>
                )}
              </Box>
              <LineChart
                // backgroundColor={color}
                width={1100}
                height={370}
                data={value}
                margin={{ top: 5, right: 30, left: 20, bottom: 20 }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis
                  dataKey="date"
                  tick={{
                    fill: "white",
                    fontWeight: 400,
                    fontSize: "12px",
                    fontStyle: "Roboto",
                  }}
                  tickFormatter={(dateStr) => {
                    const date = new Date(dateStr);
                    const month = date.toLocaleDateString("en-US", {
                      month: "short",
                    });
                    const year = date.toLocaleDateString("en-US", {
                      year: "2-digit",
                    });
                    return `${month}-${year}`; // Add the hyphen between month and year
                  }}
                  stroke="#FFFFFF" // Set X-axis label color to white
                  label={{
                    value: columns.key,
                    position: "bottom",
                    offset: 8,
                    style: {
                      textAnchor: "middle",
                      fill: "white",
                      fontWeight: 400,
                      fontSize: "12px",
                      fontStyle: "Roboto",
                    },
                  }}
                />
                <YAxis
                  label={{
                    value: columns.value,
                    angle: -90,
                    position: "insideLeft",
                    style: {
                      textAnchor: "middle",
                      fill: "white",
                      fontWeight: 400,
                      fontSize: "12px",
                      fontStyle: "Roboto",
                    },
                  }}
                  stroke="#FFFFFF" // Set Y-axis label color to white
                  tick={{
                    fill: "white",
                    fontWeight: 400,
                    fontSize: "12px",
                    fontStyle: "Roboto",
                  }}
                />
                <Tooltip
                  labelFormatter={(dateStr) =>
                    new Date(dateStr).toLocaleDateString()
                  }
                  formatter={(value) =>
                    new Intl.NumberFormat("en-US").format(value)
                  }
                  // wrapperStyle={{ color: '#FFFFFF' }} // Optional: set tooltip text color to white
                />
                <Legend
                  wrapperStyle={{
                    position: "absolute",
                    bottom: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "100%",
                    textAlign: "center",
                    fontSize: "14px",
                    fontStyle: "Roboto",
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="value"
                  name={columns.value}
                  stroke="#8884d8"
                  dot={{ r: 1 }}
                />
              </LineChart>
            </React.Fragment>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};
