import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import axios from "axios";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Paper,
  Divider,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Button,
  Drawer,
  ListItemIcon,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Fade,
  Stack,
  Select,
  IconButton,
  AppBar,
  Toolbar,
  Slide,
  InputLabel,
  CssBaseline,
  ToggleButtonGroup,
  ToggleButton,
  Link,
  CircularProgress,
  Menu,
  TextField,
  InputAdornment,
  SvgIcon,
  Skeleton,
  Breadcrumbs,
} from "@mui/material";
import {
  AttachMoney,
  Category,
  Business,
  TrendingUp,
  TrendingDown,
  Remove,
  PieChart,
  Close as CloseIcon,
  CloudUpload,
  Dashboard,
  FileDownload,
  CheckCircle,
  RadioButtonUnchecked,
  AccessTime,
  AutoAwesome,
  Campaign,
  Delete,
  ArrowDropDown,
  ArrowDropDownCircle,
  PriceCheck,
  CreditScore,
  CurrencyExchange,
  Info,
  Groups,
  PersonSearch,
  HelpOutline,
  Speed,
  PanToolAlt,
  TrendingFlat,
  PeopleAltOutlined,
  CampaignOutlined,
  FiberManualRecord,
  AccessTimeFilledOutlined,
  AccessTimeFilled,
  Co2,
  EnergySavingsLeaf,
  Insights,
  InsightsOutlined,
  ArrowUpward,
  ArrowDownward,
  HorizontalRule,
  TrendingUpOutlined,
  TrendingDownOutlined,
  TrendingFlatOutlined,
  FilterCenterFocus,
  TipsAndUpdates,
  NavigateNext,
  ReportProblem,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import FolderIcon from "@mui/icons-material/Folder";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  Cell,
  Label,
  Text,
  LineChart,
  Legend,
  Line,
  ComposedChart,
  Treemap,
  Rectangle,
  ScatterChart,
  Scatter,
  ZAxis,
  ReferenceLine,
  ReferenceArea,
} from "recharts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Tooltip as MUITooltip } from "@mui/material";
import { styled } from "@mui/system";
// import { TrendingDown, Minus, PieChart, ChevronDown ,X} from 'lucide-react';
import { TypeAnimation } from "react-type-animation";
import bggenai from "../../assets/bggenai.png";
import bgsd from "../../assets/bgsd.png";
import ailoader from "../../assets/ailoader.gif";
import bgSS from "../../assets/bgSS.png";
import { DeleteIcon, Search } from "lucide-react";
import { IoIosLocate } from "react-icons/io";
import { FaHome, FaUpload, FaChartBar, FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
// import { withTranslation } from "react-google-multi-lang";
// import { translateBulk } from "./translationService";
import { TbTopologyStar3 } from "react-icons/tb";

export const InsightContainer = ({
  title,
  topCategories,
  topContributors,
  marketSize,
  costStructure,
  demandandSupply,
  topSuppliers,
  categoryChartData,
  clientNames,
  categoryNews,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [showNewsOverlay, setShowNewsOverlay] = useState(false);
  console.log("cat news are", categoryNews);

  const insightsArray = Array.isArray(topContributors)
    ? topContributors
    : [topContributors];

  const LoadingGif = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50px",
        width: "auto",
      }}
    >
      <img
        src={ailoader}
        alt="Loading..."
        style={{ width: "30px", height: "30px" }}
      />
    </Box>
  );

  const TrendIcon = ({ trend }) => {
    switch (trend) {
      case "up":
        return <TrendingUp color="success" />;
      case "down":
        return <TrendingDown color="error" />;
      default:
        return <Remove color="warning" />;
    }
  };

  const leftGridRef = useRef(null);
  const rightGridRef = useRef(null);

  useEffect(() => {
    const adjustHeight = () => {
      if (leftGridRef.current && rightGridRef.current) {
        const leftHeight = leftGridRef.current.clientHeight;
        const rightHeight = rightGridRef.current.clientHeight;

        if (rightHeight > leftHeight) {
          leftGridRef.current.style.height = `${rightHeight}px`;
        } else {
          leftGridRef.current.style.height = "auto";
        }
      }
    };

    adjustHeight();
    window.addEventListener("resize", adjustHeight);

    return () => window.removeEventListener("resize", adjustHeight);
  }, [selectedCategory]); // Re-run when selectedCategory changes

  // const sortedNews= []

  console.log("client name", clientNames);
  const { client_names } = clientNames;
  const clientName = client_names[0]; // Directly access the first item

  const selectedCategoryName = topCategories[selectedCategory];
  // Get the spend value from topContributors using selected category name
  const spendValue = topContributors[selectedCategoryName]?.spend || "N/A";
  const topc = topContributors[selectedCategoryName]?.topContributors || "N/A";

  const [number, restContent] = topc.split(";", 2);

  const costStruc = costStructure[selectedCategoryName] || "N/A";

  const dandS = demandandSupply[selectedCategoryName]?.text || "N/A";

  const mSize = marketSize[selectedCategoryName] || "N/A";

  const [label, value] = mSize.split(":", 2);

  const topSup = topSuppliers[selectedCategoryName] || "N/A";

  const selectedCategoryNews = categoryNews[selectedCategoryName] || [];

  // const content = categoryMessages[selectedCategoryName] || "Loading Summary...";

  // const formattedContent = content.split(/(?=###)/g).map((part, index) => {
  //   const [heading, ...contentParts] = part.split('\n');
  //   const content = contentParts.join('\n').trim();

  //   if (heading.startsWith('###')) {
  //     return (
  //       <React.Fragment key={index}>
  //         <Typography
  //           sx={{
  //             fontStyle: "Roboto",
  //             fontSize: "14px",
  //             fontWeight: "bold",
  //             lineHeight: "1.5",
  //             marginBottom: "8px",
  //             color: "white"
  //           }}
  //         >
  //           {heading.replace('###', '').trim()}
  //         </Typography>
  //         <Typography
  //           sx={{
  //             fontStyle: "Roboto",
  //             fontSize: "12px",
  //             lineHeight: "1.5",
  //             marginBottom: "16px",
  //           }}
  //         >
  //           {content}
  //         </Typography>
  //       </React.Fragment>
  //     );
  //   } else {
  //     return (
  //       <Typography
  //         key={index}
  //         sx={{
  //           fontStyle: "Roboto",
  //           fontSize: "12px",
  //           lineHeight: "1.5",
  //           marginBottom: "8px",
  //         }}
  //       >
  //         {part.trim()}
  //       </Typography>
  //     );
  //   }
  // });

  // const cs_summary = categorySummaryCS[selectedCategoryName] || [];
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: "16px",
          gap: "16px",
          border: "1px solid #FFFFFF0F",
          borderRadius: "10px",
          marginTop: "8rem",
          display: "flex", // Added to make the gap property work
          flexDirection: "column", // Assuming vertical gap
          backgroundColor: "#FFFFFF08",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // marginBottom: '0.3rem',
          }}
        >
          <Typography
            variant="h6"
            color="white"
            style={{
              fontStyle: "Roboto",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "18px",
              color: "white",
            }}
          >
            <b>Deep dive for top 3 categories</b>
          </Typography>
        </Box>

        <Select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          sx={{
            maxWidth: "120px",
            width: "auto", // Hug (70px)
            height: "24px", // Fill (24px)
            padding: "4px 8px",
            fontStyle: "Roboto",
            fontSize: "12px",
            fontWeight: 500,
            gap: "4px",
            borderRadius: "4px",
            opacity: 1, // Note: Changed from 0 to 1 for visibility. 0 would make it invisible.
            background: "#FFFFFF0F",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #E2E8F00F",
            "& .MuiSelect-select": {
              padding: "0", // Remove default padding
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none", // Remove default outline
            },
          }}
          IconComponent={(props) => (
            <ArrowDropDownCircle
              {...props}
              style={{
                color: "white",
                fontSize: "14px", // Adjust icon size to match text
              }}
            />
          )}
          MenuProps={{
            PaperProps: {
              style: {
                background: "white",
              },
            },
            MenuListProps: {
              sx: {
                fontStyle: "Roboto",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "15px",
              },
            },
          }}
        >
          {topCategories.map((category, index) => (
            <MenuItem
              key={index}
              value={index}
              sx={{
                fontSize: "12px",
                fontStyle: "Roboto",
                fontWeight: 500,
                lineHeight: "15px",
              }}
            >
              {category}
            </MenuItem>
          ))}
        </Select>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          sx={{ marginTop: "0.5rem" }}
        >
          {/* Left half - KPS and Insights */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              backgroundColor: "#FFFFFF0F",
              border: "1px solid transparent",
              width: "510px",
              borderRadius: "10px",
              marginRight: "0.25rem",
              marginLeft: "0.5rem",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Spend */}
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #FFFFFF0F",
                  paddingBottom: "8px",
                }}
              >
                <span
                  style={{
                    fontStyle: "Roboto",
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "white",
                  }}
                >
                  <b>Spend</b>
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    fontStyle: "Roboto",
                    fontWeight: 500,
                    color: "white",
                  }}
                >
                  {spendValue !== "N/A" ? spendValue : <Skeleton width={100} />}
                </span>
              </Typography>
            </Box>

            {/* Suppliers */}
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body1"
                sx={{ marginBottom: "4px", fontSize: "1rem" }}
              >
                <span
                  style={{
                    fontStyle: "Roboto",
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "white",
                  }}
                >
                  <b>
                    {selectedCategoryName} suppliers for the client
                    {/* {clientName || <Skeleton width={100} />} */}
                  </b>
                </span>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                  fontStyle: "Roboto",
                  fontWeight: 500,
                  color: "white",
                  borderBottom: "1px solid #FFFFFF0F",
                  paddingBottom: "8px",
                }}
              >
                {topc !== "N/A" ? (
                  <>
                    {number} suppliers;
                    {restContent && <span> {restContent}</span>}
                  </>
                ) : (
                  <Skeleton width="100%" />
                )}
              </Typography>
            </Box>

            {/* Cost Structure */}
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="subtitle1"
                color="white"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1rem",
                  marginBottom: "4px",
                }}
              >
                <PieChart sx={{ mr: 0.25, fontSize: "14px" }} />
                <span
                  style={{
                    fontStyle: "Roboto",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  <b>Cost Structure</b>
                </span>
              </Typography>
              <Typography
                variant="body1"
                className="print-white" // Apply this class
                sx={{
                  fontSize: "12px",
                  fontStyle: "Roboto",
                  fontWeight: 500,
                  color: "white",
                  borderBottom: "1px solid #FFFFFF0F",
                  paddingBottom: "8px",
                }}
              >
                {costStruc !== "N/A" ? (
                  costStruc
                    .replace(/['"]/g, "")
                    .split("*")
                    .filter(Boolean)
                    .map((line, index) => (
                      <div
                        key={index}
                        style={{ marginBottom: "4px", fontStyle: "Roboto" }}
                      >
                        -{line.trim()}
                      </div>
                    ))
                ) : (
                  <>
                    <Skeleton width="100%" />
                    <Skeleton width="80%" />
                    <Skeleton width="90%" />
                  </>
                )}
              </Typography>
            </Box>

            {/* Demand and Supply Trends */}
            {/* <Box sx={{ mb: 2 }}>
              <Typography
                variant="subtitle1"
                color="white"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "4px",
                }}
              >
                <TrendingUp sx={{ mr: 0.25, fontSize: "14px" }} />
                <span style={{ fontStyle: "Roboto", fontSize: "14px", fontWeight: 500 }}>
                  <b>Demand and Supply Trends</b>
                </span>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                  fontStyle: "Roboto",
                  fontWeight: 500,
                  color: "white",
                  paddingBottom: "8px",
                  borderBottom: "1px solid #FFFFFF0F",
                  "& > div:not(:last-child)": {
                    marginBottom: "4px",
                  },
                }}
              >
                {dandS !== 'N/A' ? (
                  dandS
                    .replace(/["']/g, "")
                    .split("*")
                    .filter(Boolean)
                    .map((item, index) => (
                      <div key={index}>-{item.trim()}</div>
                    ))
                ) : (
                  <>
                    <Skeleton width="100%" />
                    <Skeleton width="85%" />
                    <Skeleton width="95%" />
                  </>
                )}
              </Typography>
            </Box> */}

            {/* Top Suppliers */}
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="body1"
                color="white"
                sx={{
                  fontSize: "1rem",
                  marginBottom: "4px",
                }}
              >
                <TbTopologyStar3
                  style={{
                    marginRight: 0.25,
                    fontSize: "15px",
                    color: "white",
                  }}
                />
                <span
                  style={{
                    fontStyle: "Roboto",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  <b>Top Suppliers</b>
                </span>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "white",
                }}
              >
                {topSup !== "N/A" ? (
                  topSup.replace(/['"]/g, "")
                ) : (
                  <>
                    <Skeleton width="100%" />
                    <Skeleton width="75%" />
                  </>
                )}
              </Typography>
            </Box>
          </Grid>
          {/* Right half - Summary of Latest Market Insights */}
          {/* Right half - Summary of Latest Market Insights */}
          <Grid
            item
            xs={12}
            md={5.5}
            sx={{
              backgroundColor: "#FFFFFF0F",
              width: "100%",
              maxWidth: "510px",
              border: "1px solid transparent",
              borderRadius: "10px",
              padding: { xs: "10px", sm: "20px" },
              gap: "12px",
            }}
          >
            <Box display="flex" flexDirection="column">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                flexWrap="wrap"
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: "Roboto",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "16.94px",
                    textAlign: "left",
                    color: "white",
                    marginBottom: { xs: "10px", sm: 0 },
                  }}
                >
                  <Campaign sx={{ mr: 0.5, verticalAlign: "middle" }} /> Latest
                  Market Insights
                </Typography>
                {selectedCategoryNews.length > 0 && (
                  <Button
                    variant="contained"
                    onClick={() => setShowNewsOverlay(true)}
                    sx={{
                      fontStyle: "Roboto",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "15px",
                      background:
                        "linear-gradient(90deg, #496AB3 2.73%, #A237FE 95.45%)",
                      "&:hover": {
                        background:
                          "linear-gradient(90deg, #3B569E 2.73%, #8A2EE0 95.45%)",
                      },
                      color: "white",
                      cursor: "pointer",
                      display: "inline-flex",
                      alignItems: "center",
                      textTransform: "none",
                    }}
                  >
                    Read more
                  </Button>
                )}
              </Box>
              {selectedCategoryNews.length > 0 ? (
                <>
                  {selectedCategoryNews
                    .filter(
                      (newsItem, index, self) =>
                        index ===
                        self.findIndex((item) => item.title === newsItem.title)
                    )
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .slice(0, 2)
                    .map((newsItem, index) => (
                      <Card
                        key={index}
                        sx={{
                          mb: 2,
                          display: "flex",
                          backgroundColor: "rgba(129, 129, 129, 0.25)",
                          boxShadow: "none",
                          border: "1px solid rgba(255, 255, 255, 0.5)",
                          borderRadius: "8px",
                          width: "100%",
                          height: "120px",
                          flexDirection: { xs: "column", sm: "row" },
                          overflow: "hidden",
                        }}
                      >
                        <CardMedia
                          component="img"
                          sx={{
                            width: { xs: "100%", sm: "100px" },
                            height: { xs: "140px", sm: "140px" },
                            objectFit: "cover",
                          }}
                          image={
                            newsItem.thumbnail &&
                            newsItem.thumbnail.trim() !== ""
                              ? newsItem.thumbnail
                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0x2fwUoET9YatuWt5NZjc_2KiDFUCLF4k8w&s"
                          }
                          alt="News thumbnail"
                        />

                        <CardContent
                          sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            color: "white",
                            fontSize: "0.8rem",
                            padding: "8px",
                            "&:last-child": { paddingBottom: "8px" },
                            minHeight: 0,
                            overflow: "hidden",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              fontStyle: "Roboto",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "16.94px",
                              textAlign: "left",
                              mb: 0.5,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {newsItem.title}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              fontStyle: "Roboto",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "16.94px",
                              textAlign: "left",
                              color: "#FFFFFF66",
                              mb: 0.5,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            <a
                              href={newsItem.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              {newsItem.link}
                            </a>
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-end",
                              width: "100%",
                              flexWrap: "wrap",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                fontStyle: "Roboto",
                                fontSize: "12px",
                                fontWeight: 500,
                                lineHeight: "16.94px",
                                color: "#FFFFFF66",
                                marginRight: "10px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                maxWidth: {
                                  xs: "100%",
                                  sm: "calc(100% - 100px)",
                                },
                              }}
                            >
                              {newsItem.source_name}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontStyle: "Roboto",
                                fontSize: "12px",
                                fontWeight: 500,
                                lineHeight: "16.94px",
                                color: "rgba(255, 255, 255, 0.7)",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {/* {(() => {
                      const parts = newsItem.date.split(",");
                      return parts.length > 2
                        ? parts.slice(0, 2).join(",")
                        : newsItem.date;
                    })()} */}

                              {newsItem.date
                                ? (() => {
                                    const parts = newsItem.date.split(",");
                                    return parts.length > 2
                                      ? parts.slice(0, 2).join(",")
                                      : newsItem.date;
                                  })()
                                : "no date"}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    ))}
                  {/* <Card
          sx={{
            mt: 2,
            backgroundColor: "rgba(129, 129, 129, 0.25)",
            boxShadow: "none",
            border: "1px solid rgba(255, 255, 255, 0.5)",
            borderRadius: "8px",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <CardContent
            sx={{
              color: "white",
              padding: "16px",
              "&:last-child": { paddingBottom: "16px" },
            }}
          >
            <Typography
      variant="h6"
      sx={{
        fontStyle: "Roboto",
        fontSize: "14px",
        fontWeight: 500,
        marginBottom: "8px",
        fontWeight: "bold"
      }}
    >
      Market Summary
    </Typography>
    {formattedContent}
          </CardContent>
        </Card> */}
                </>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  minHeight="200px"
                >
                  <LoadingGif />
                  <Typography
                    variant="body1"
                    sx={{
                      fontStyle: "Roboto",
                      fontSize: "14px",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <i>Fetching Latest Market Insights for you..</i>
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "1rem" }}>
            {/* <Card
            sx={{
              backgroundColor: "rgba(129, 129, 129, 0.25)",
              boxShadow: "none",
              border: "1px solid rgba(255, 255, 255, 0.5)",
              borderRadius: "8px",
              width: "100%",
              overflow: "hidden",
            }}
          >
           <CardContent
  sx={{
    color: "white",
    padding: "16px",
    "&:last-child": { paddingBottom: "16px" },
  }}
>
  <Typography
    variant="h6"
    sx={{
      fontStyle: "Roboto",
      fontSize: "14px",
      fontWeight: 700,
      marginBottom: "8px",
    }}
  >
    <Insights sx={{ mr: 0.3, fontSize: "20px" }} /> Market Summary
  </Typography>

  {/* <Typography
    variant="subtitle1"
    sx={{
      fontStyle: "Roboto",
      fontSize: "13px",
      fontWeight: 600,
      marginTop: "16px",
      marginBottom: "8px",
    }}
  >
    Overall Market Overview
  </Typography> */}
            {/* {formattedContent} */}

            {/* <Typography
    variant="subtitle1"
    sx={{
      fontStyle: "Roboto",
      fontSize: "13px",
      fontWeight: 600,
      marginTop: "16px",
      marginBottom: "8px",
    }}
  >
    Cost Structure Insights
  </Typography> */}
            {/* <Typography 
    sx={{
      fontStyle: "Roboto",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: 1.5,
    }}
  >
    {cs_summary}
  </Typography> */}
            {/* </CardContent>
          </Card> */}
          </Grid>
        </Grid>

        {/* News Overlay Dialog */}
        <Dialog
          open={showNewsOverlay}
          onClose={() => setShowNewsOverlay(false)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              backgroundImage: `url(${bggenai})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: "rgba(129, 129, 129)",
              boxShadow: "none",
            },
          }}
        >
          <DialogTitle sx={{ color: "white", fontSize: "0.9rem" }}>
            Latest Market Insights
            <IconButton
              onClick={() => setShowNewsOverlay(false)}
              sx={{ position: "absolute", right: 8, top: 8, color: "white" }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            dividers
            sx={{ borderColor: "rgba(255, 255, 255,0.5)" }}
          >
            {selectedCategoryNews
              .filter(
                (newsItem, index, self) =>
                  index ===
                  self.findIndex((item) => item.title === newsItem.title)
              )
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .map((newsItem, index) => (
                <Card
                  key={index}
                  sx={{
                    mb: 2,
                    display: "flex",
                    // backgroundImage: `url(${bggenai})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "#181921",
                    boxShadow: "none",
                    border: "1px solid rgba(255, 255, 255)",
                    borderRadius: "8px",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      width: "199px",
                      height: "152px",
                      borderRadius: "8px 0 0 0",
                    }}
                    image={
                      newsItem.thumbnail ||
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0x2fwUoET9YatuWt5NZjc_2KiDFUCLF4k8w&s"
                    }
                    alt="News thumbnail"
                  />
                  <CardContent
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      color: "white",
                      fontSize: "0.9rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ fontSize: "0.9rem" }}
                    >
                      {newsItem.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{ fontSize: "0.9rem" }}
                    >
                      <a
                        href={newsItem.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        {newsItem.link}
                      </a>
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="rgba(255, 255, 255, 0.7)"
                        sx={{ fontSize: "0.8rem" }}
                      >
                        {newsItem.source_name}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="rgba(255, 255, 255, 0.7)"
                        sx={{ fontSize: "0.8rem" }}
                      >
                        {newsItem.date
                          ? (() => {
                              const parts = newsItem.date.split(",");
                              return parts.length > 2
                                ? parts.slice(0, 2).join(",")
                                : newsItem.date;
                            })()
                          : "no date"}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              ))}
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};
